import { Link } from "gatsby";
import React from "react";
import { Container } from "../styles/UI";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styled from "styled-components";

import taxcelBgLogo from "../images/home/logo-negative.svg";
import Button from "../DesignSystem/Button";

const Hero = styled.section`
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 24px;
      }
    }
  }

  button:last-child:hover {
    background: var(--color-white);
  }

  button:last-child {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

const Politica = styled.div`
  padding: 80px 40px;

  /* h1, h2, h3 {
    color: ${(p) => p.theme.primary};
  } */

  h1 {
    margin-bottom: 80px;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    /* or 123% */

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }

  h2 {
    margin-top: 1.5em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.75rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
  }
  
  h3 {
    margin-top: 1em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.25rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.3;
    text-align: justify;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }

  ol {
    list-style-type: lower-roman;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }
`;

export default function PoliticaSI() {
  const handleClick = () => {
    navigate("/");
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Seo title="Política de Segurança da Informação" />
      <Hero>
        <Container>
          <div className="cta">
            <h1>Política de Segurança da Informação</h1>
            <p>
              Qualquer dúvida, solicitação ou reclamação em relação à nossa
              política de segurança da informação pode ser esclarecida entrando
              em contato conosco, por meio do e-mail: lgpd@taxcel.com.br ou por
              telefone: (11) 5039-4214.
            </p>
            <p>
              <b>Última Atualização:</b> setembro de 2023. <br />
              <b>Classificação da Informação:</b> Uso Público.
            </p>
            <div className="buttons">
              <Button
                ghost
                bg="secondary"
                color="secondary"
                onClick={handleClickBack}
              >
                Voltar
              </Button>
              <Button bg="secondary" color="primary" onClick={handleClick}>
                Ir para a Página Inicial
              </Button>
            </div>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </Hero>
      <Politica>
        <Container>
          <h1>
            <span>Política de Segurança da Informação</span>
          </h1>

          <h2>Definição</h2>
          <p>
            Este documento descreve diretrizes sobre a Política de Segurança da
            Informação da Taxcel, cujas regras e procedimentos são de cunho
            confidencial, publicadas internamente. Tais diretrizes orientam o
            uso aceitável dos ativos de informação da instituição, baseadas nos
            princípios de confidencialidade, integridade e disponibilidade.
          </p>

          <h2>Público-alvo</h2>
          <p>
            Taxcel, Terceiros, Prestadores de Serviços, Clientes e Parceiros.
          </p>

          <h2>Objetivo</h2>
          <ul>
            <li>
              Estabelecer diretrizes e normas de Segurança da Informação que
              permitam aos colaboradores da Taxcel adotar padrões de
              comportamento seguro, adequados às suas metas e necessidades;
            </li>
            <li>
              Orientar colaboradores quanto à adoção de controles e processos
              para atendimento dos requisitos de Segurança da Informação;
            </li>
            <li>
              Capacitar colaboradores da Taxcel no que se refere à prevenção,
              detecção e resposta a incidentes de Segurança da Informação;
            </li>
            <li>
              Prevenir possíveis causas de incidentes de Segurança da
              Informação;
            </li>
            <li>
              Resguardar ativos de informação e/ou tecnológicos da Taxcel,
              garantindo requisitos de confidencialidade, integridade e
              disponibilidade;
            </li>
            <li>
              Minimizar os riscos de perdas financeiras, da confiança do cliente
              ou de qualquer outro impacto negativo no negócio da Taxcel como
              resultado de falhas de segurança.
            </li>
          </ul>

          <h2>Responsabilidades</h2>
          <p>
            A Política de Segurança da Informação da Taxcel trata sobre
            responsabilidades gerais da instituição, de seus colaboradores e
            terceiros, bem como a Alta Administração.
          </p>
          <ul>
            <li>
              A Taxcel nomeará um ou mais representantes de segurança
              responsáveis pela coordenação e monitoramento das regras e
              procedimentos de segurança.
            </li>
            <li>
              O pessoal da Taxcel com acesso aos Dados estará sujeito a
              obrigações de confidencialidade.
            </li>
            <li>
              A Taxcel terá um programa de gestão de risco em vigor para
              identificar, avaliar e tomar as ações apropriadas com relação aos
              riscos relacionados ao processamento dos Dados.
            </li>
          </ul>
          <h2>Conscientização e Treinamentos de Segurança da Informação</h2>
          <p>
            A Taxcel define diretrizes de educação contínua para o aculturamento
            de boas práticas de segurança e disseminação para utilização no dia
            a dia dos colaboradores, seja para fins profissionais quanto para
            fins pessoais. A Política aborda procedimentos utilizados no
            programa de conscientização da instituição, tais como treinamentos e
            informativos internos.
          </p>
          <p>No treinamento de segurança a Taxcel irá:</p>
          <ol>
            <li>
              Informar seu pessoal sobre os procedimentos de segurança
              relevantes e suas respectivas funções.
            </li>
            <li>
              Informar o seu pessoal sobre as possíveis consequências da
              violação das regras e procedimentos de segurança.
            </li>
            <li>Utilizar apenas dados anônimos no treinamento.</li>
          </ol>

          <h2>Gestão de Riscos de Segurança da Informação</h2>
          <p>
            A gestão de riscos cibernéticos é de responsabilidade da área de
            Segurança da Informação. Este processo identifica os requisitos de
            segurança relacionados às necessidades da instituição. A gestão de
            riscos cibernéticos é contínua e define contextos internos e
            externos para avaliação, além de tratar riscos identificados de modo
            que sejam reduzidos a níveis aceitáveis.
          </p>

          <h2>Gestão de Senhas</h2>
          <p>
            A Taxcel faz o uso das melhores práticas de uso de senhas, exigindo
            uma complexidade determinada para criação, assim como evita a
            reutilização de senhas anteriores. As senhas são geradas com a
            exigência de caracteres mínimos definidos, bloqueio por tentativa
            sem sucesso e contêm uma periodicidade exigida para alteração.
          </p>

          <h2>Gestão dos Ativos</h2>
          <p>
            A Taxcel manterá um inventário completo de ativos de sua
            infraestrutura, rede, aplicativos e ambientes em nuvem. A Taxcel
            também manterá um inventário de todas as suas mídias nas quais os
            Dados são armazenados. O acesso aos inventários de tais mídias será
            restrito ao pessoal das Partes com autorização por escrito para tal
            acesso.
          </p>

          <h2>Proteção e Classificação da Informação</h2>
          <p>
            A Taxcel estabelece diretrizes para a classificação, manuseio e
            rotulagem dos ativos de informação da empresa. O documento interno
            prevê todas as diretrizes utilizadas para a classificação da
            informação, descreve suas categorias, prevê ainda diretrizes para o
            manuseio da informação, para o descarte da informação, descreve
            regras sobre prevenção a vazamento de dados e políticas, sobre
            cópias e restauração de dados (backup e restore), bem como sobre
            criptografia.
          </p>

          <h2>Uso Aceitável de Recursos Tecnológicos</h2>
          <p>
            Os recursos de tecnologia da Taxcel devem ser utilizados de forma
            profissional, ética e legal, conforme definido no termo de
            responsabilidade aplicável. A Política de Segurança da Informação
            aborda a definição de recursos tecnológicos, além de regras que
            tratam deste tema, pelas quais os colaboradores e terceiros da
            Taxcel devem seguir.
          </p>

          <h2>Gestão de Identidade e Acessos</h2>
          <p>
            A Taxcel estabelece diretrizes gerais para acesso a ativos e
            sistemas de informação. Toda gestão de acessos é de responsabilidade
            da área de Tecnologia da Informação e é baseada no princípio da
            necessidade de acesso à informação para a execução das atividades
            laborais do colaborador.
          </p>
          <p>A Política define diretrizes, tais como:</p>
          <ul>
            <li>Perfis de Acessos das Áreas de Negócio;</li>
            <li>
              Processo de Admissão ou Transferência de Área de Colaboradores;
            </li>
            <li>Processo de Desligamento de Colaboradores;</li>
            <li>Acesso de Terceiros, Visitantes e Temporários;</li>
            <li>Acesso a Banco de Dados;</li>
            <li>Acesso Remoto;</li>
            <li>Acesso Físico;</li>
            <li>Revisão de Acessos;</li>
            <li>Parametrização de Senhas; e</li>
            <li>Múltiplo Fator de Autenticação.</li>
          </ul>
          <br />
          <p>Em relação à política de acesso, a Taxcel irá:</p>
          <ol>
            <li>
              Manter e atualizar um registro do pessoal autorizado a acessar os
              Dados de Cliente por meio de seus sistemas.
            </li>
            <li>
              Quando for responsável pelo provisionamento de acesso, fornecer
              imediatamente as credenciais de autenticação.
            </li>
            <li>
              Desativar as credenciais de autenticação quando essas credenciais
              não tiverem sido usadas por um período de tempo (esse período de
              não uso não deve exceder 90 dias).
            </li>
            <li>
              Desativar as credenciais de autenticação após a notificação de que
              o acesso não é mais necessário (por exemplo, demissão de
              funcionário, reatribuição de projeto, etc.) dentro de cinco dias
              úteis.
            </li>
            <li>
              Identificar o pessoal que pode conceder, alterar ou cancelar o
              acesso autorizado aos dados e recursos.
            </li>
            <li>
              Certificar-se de que, onde mais de um indivíduo tiver acesso aos
              seus sistemas contendo Dados do Cliente, os indivíduos tenham
              identificadores / logins exclusivos (ou seja, nenhum id
              compartilhado).
            </li>
            <li>
              Permitir que seu pessoal de suporte técnico tenha acesso aos Dados
              do Cliente apenas quando necessário.
            </li>
            <li>
              Manter controles que permitam o acesso de emergência aos sistemas
              de produção por meio de firefighter ids, ids temporários ou ids
              gerenciados por uma solução de gerenciamento de acesso
              privilegiado (PAM).
            </li>
            <li>
              O acesso aos Dados do Cliente em seus sistemas apenas aos
              indivíduos que precisam de tal acesso para desempenhar suas
              funções.
            </li>
            <li>
              Limitar o acesso aos Dados do Cliente em seus sistemas apenas aos
              dados minimamente necessários para executar os serviços.
            </li>
            <li>
              Reforçar a segregação de funções entre seus ambientes de forma que
              nenhuma pessoa individual tenha acesso para realizar tarefas que
              criam um conflito de interesse de segurança (por exemplo,
              desenvolvedor / revisor, desenvolvedor / testador).
            </li>
          </ol>

          <h2>Segurança Física e Ambiental</h2>
          <p>
            a) <b>Acesso físico às instalações.</b> A Taxcel só permitirá que
            indivíduos autorizados acessem suas instalações onde os sistemas de
            informação que processam Dados estão localizados.
          </p>
          <p>
            b) <b>Acesso físico aos componentes.</b> A Taxcel manterá registros
            da mídia de entrada e saída contendo Dados, incluindo o tipo de
            mídia, o remetente/ destinatário autorizado, data e hora, o número
            de mídia e os tipos de Dados contidos em tais mídias.
          </p>
          <p>
            c) <b>Descarte de componentes.</b> A Taxcel usará processos padrão
            da indústria (por exemplo, ISO 27001, CIS Sans 20 e/ou NIST
            Cyber-Security Framework, conforme aplicável) para excluir os Dados
            quando eles não forem mais necessários.
          </p>

          <h2>Criptografia</h2>
          <p>
            Os ativos de informação da Taxcel possuem criptografia adequada, a
            fim de garantir a proteção em todo o ciclo de vida da informação, em
            conformidade com padrões de segurança dos órgãos reguladores.
          </p>

          <h2>Autenticação</h2>
          <p>Em relação à autenticação a Taxcel irá:</p>
          <ol>
            <li>
              Utilizar as práticas padrão da indústria (por exemplo, ISO 27001,
              CIS Sans 20 e/ou NIST Cyber-Security Framework, conforme
              aplicável) para identificar e autenticar usuários que tentam
              acessar seus sistemas de informação.
            </li>
            <li>
              Quando os mecanismos de autenticação são baseados em senhas,
              exigir que as senhas sejam renovadas regularmente.
            </li>
            <li>
              Quando os mecanismos de autenticação são baseados em senhas,
              exigir que a senha contenha pelo menos oito caracteres e três dos
              quatro tipos de caracteres a seguir: numérico (0-9), minúsculo
              (az), maiúsculo (AZ), especial (por exemplo, !, *, &, etc.).
            </li>
            <li>
              Certificar-se de que identificadores desativados ou expirados não
              sejam concedidos a outros indivíduos.
            </li>
            <li>
              Monitorar as tentativas repetidas de obter acesso aos seus
              sistemas de informação usando uma senha inválida.
            </li>
            <li>
              Manter procedimentos padrão da indústria (por exemplo, ISO 27001,
              CIS Sans 20 e/ou NIST Cyber-Security Framework, conforme
              aplicável) para desativar senhas que foram corrompidas ou
              divulgadas inadvertidamente.
            </li>
            <li>
              Utilizar as práticas de proteção de senha padrão da indústria (por
              exemplo, ISO 27001, CIS Sans 20 e/ou NIST Cyber-Security
              Framework, conforme aplicável), incluindo práticas destinadas a
              manter a confidencialidade e integridade das senhas quando são
              atribuídas e distribuídas, bem como durante o armazenamento.
            </li>
            <li>
              A Taxcel implementará a autenticação multifator para acesso
              interno.
            </li>
          </ol>

          <h2>Desenvolvimento de Software</h2>
          <p>
            A Taxcel desenvolve suas aplicações conforme procedimentos,
            documentos e instruções de trabalhos internos, seguindo práticas de
            segurança da informação, alinhado com a Política de Segurança
            interna.
          </p>
          <p>
            Os ambientes de produtivos são segregados dos demais ambientes e com
            acesso por usuários previamente autorizados ou por ferramentas
            homologadas.
          </p>
          <p>
            Todos os sistemas ou aplicações adquiridas de terceiros, devem
            seguir diretrizes definidas na Política de Segurança da Informação e
            devidamente homologados.
          </p>

          <h2>Proteção Contra Códigos Maliciosos</h2>
          <p>
            A Taxcel define diretrizes e utiliza ferramentas líderes de mercado
            para a proteção contra ameaças de códigos maliciosos (malwares).
            Além disso, a Taxcel conta com soluções de segurança baseadas em IA
            (Inteligência Artificial), para identificação, detecção e resposta
            imediata a ameaças.
          </p>

          <h2>Monitoramento de Segurança</h2>
          <p>
            A Política de Segurança da Informação trata sobre o monitoramento de
            segurança, descrevendo os aspectos necessários para identificação de
            eventuais ameaças. A Taxcel conta com práticas, procedimentos e
            processos eficazes para monitorar as atividades relativas à
            segurança.
          </p>

          <h2>Gestão de Vulnerabilidade e Conformidade</h2>
          <p>
            A Taxcel possui processos de gestão de vulnerabilidade e
            conformidade, de modo que as seguintes diretrizes estão
            estabelecidas:
          </p>
          <ul>
            <li>Gestão de Vulnerabilidade;</li>
            <li>Gestão de Conformidade;</li>
            <li>Testes Periódicos de Segurança; e</li>
            <li>Correções de Segurança (Gestão de Patch).</li>
          </ul>
          <p>
            Pelo menos anualmente, a Taxcel realizará avaliações de penetração e
            vulnerabilidade nos ambientes de TI da Taxcel de acordo com as
            políticas de segurança interna e práticas padrão da Taxcel.
          </p>

          <h2>Projeto e Gerenciamento de Redes e Aplicativos</h2>
          <ul>
            <li>
              a) Possuir controles para evitar que indivíduos obtenham acesso
              não autorizado aos Dados do Cliente em seus sistemas.
            </li>
            <li>
              b) Utilizar a prevenção contra perda de dados baseada em e-mail
              para monitorar ou restringir a movimentação de dados sensíveis.
            </li>
            <li>
              c) Utilizar a filtragem da web baseada em rede para impedir o
              acesso a sites não autorizados.
            </li>
            <li>
              d) Utilizar IDs de usuário temporários para acesso de produção.
            </li>
            <li>
              e) Utilizar detecção e/ou prevenção de intrusão de rede em seus
              sistemas.
            </li>
            <li>f) Utilizar padrões de codificação seguros.</li>
            <li>
              g) Procurar e corrigir vulnerabilidades OWASP em seus sistemas.
            </li>
            <li>
              h) Manter os padrões de configuração de segurança de servidor,
              rede, infraestrutura, aplicativos e nuvem atualizados.
            </li>
            <li>
              i) Realizar uma varredura em seus ambientes para garantir que as
              vulnerabilidades de configuração identificadas foram corrigidas.
            </li>
          </ul>

          <h2>Gerenciamento de Patch</h2>
          <p>
            A Taxcel terá um procedimento de gerenciamento de patch que implanta
            patches de segurança para seus sistemas utilizados para processar
            Dados, que inclua:
          </p>
          <ol>
            <li>
              Tempo definido permitido para implementação de patches (não
              superior a 90 dias para patches altos ou médios conforme definido
              pela respectiva norma da Parte); e
            </li>
            <li>
              Processo estabelecido para lidar com patches de emergência ou
              críticos assim que possível.
            </li>
          </ol>

          <h2>Backup</h2>
          <p>
            A Taxcel adota soluções de Backup e Disaster Recovery para a
            proteção de seus dados contra perda de informação.
          </p>
          <p>
            Testes periódicos são adotados para garantir a integridade das
            informações, averiguar a eficácia dos processos e estabelecer
            melhorias.
          </p>
          <p>A Taxcel deverá:</p>
          <ol>
            <li>
              Possuir procedimentos específicos de recuperação de dados com
              relação aos seus sistemas, desenvolvidos para permitir a
              recuperação dos Dados do Cliente mantidos em seus sistemas.
            </li>
            <li>
              Revisar seus procedimentos de recuperação de dados pelo menos uma
              vez por ano.
            </li>
            <li>
              Registrar os esforços de restauração de dados em relação aos seus
              sistemas, incluindo a pessoa responsável, a descrição dos dados
              restaurados e, quando aplicável, a pessoa responsável e quais
              dados (se houver) tiveram que ser inseridos manualmente no
              processo de recuperação de dados.
            </li>
          </ol>

          <h2>Respostas a Incidentes de Segurança</h2>
          <p>
            A Taxcel define diretrizes para prevenir, responder e tratar
            adequadamente incidentes de Segurança que estejam impactando ou
            podem vir a impactar ativos/serviços de informação ou recursos
            tecnológicos da instituição.
          </p>
          <p>
            Neste tópico, a Política trata sobre responsabilidades das áreas na
            prevenção e resposta a incidentes.
          </p>
          <p>
            Além disso, a Política descreve regras de priorização e severidade
            com relação a possíveis incidentes, procedimentos sobre a definição
            de autoridades e regras para a elaboração de cenários de testes de
            continuidade de negócios.
          </p>
          <p>
            Cabe ainda ressaltar que a Taxcel possui um Plano de Respostas a
            Incidentes, contendo metodologia e diretrizes para o tratamento de
            incidentes de Segurança Cibernéticas.
          </p>

          <h2>Gestão de Continuidade de Negócios</h2>
          <p>
            A Taxcel realiza a gestão de continuidade de negócios com soluções,
            estratégias e procedimentos a serem executados durante eventuais
            cenários de contingência alinhados com o propósito e metas
            estratégicas da instituição. Para tal, a Taxcel possui um Plano de
            Continuidade de Negócios (PCN) que cumpre funções definidas em
            documentos internos.
          </p>

          <h2>Gestão de Terceiros</h2>
          <p>
            A Taxcel estabelece diretrizes para profissionais terceiros em suas
            dependências ou para contratação de serviços.
          </p>
          <p>
            A Taxcel possui regras de diligência adicionais para terceiros
            considerados relevantes, que são aqueles que armazenam ou processam
            dados considerados críticos em estrutura tecnológica não pertencente
            a Taxcel.
          </p>

          <h2>Segurança em Dispositivos Móveis</h2>
          <p>
            A Taxcel define diretrizes para utilização segura de dispositivos
            móveis, bem como as atribuições das áreas responsáveis pelo
            monitoramento.
          </p>
          <p>
            A Taxcel manterá uma política para seus dispositivos móveis que:
          </p>
          <ol>
            <li>Imponha a criptografia do dispositivo.</li>
            <li>Proíba o uso de aplicativos na lista negra.</li>
            <li>
              Proíba a inscrição de dispositivos móveis que tenham sido “jail
              broken”.
            </li>
          </ol>

          <h2>Segurança em Redes</h2>
          <p>
            A Taxcel possui ferramentas de segurança capazes de detectar e
            responder tentativas de intrusão em seu ambiente. Neste tópico, a
            Política aborda, também, regras sobre a rede Sem Fio corporativa e
            pública.
          </p>

          <h2>Privacidade de Dados Pessoais</h2>
          <p>
            A Taxcel garante que o propósito do tratamento dos dados pessoais
            não seja ilícito ou abusivo, assim como garante o direito
            fundamental à privacidade relativas à LGPD – Lei Geral de Proteção
            de Dados Pessoais (Lei nº 13.709, de 14 de agosto de 2018).
          </p>
          <p>A Taxcel irá:</p>
          <ol>
            <li>
              Classificar os Dados para ajudar a identificá-los e permitir que o
              acesso a eles seja adequadamente restrito.
            </li>
            <li>
              Limitar o acesso aos Dados ao mínimo necessário para realizar os
              serviços e manter procedimentos para o descarte assim que sua
              finalidade de uso for atingida.
            </li>
            <li>
              Exigir que seu pessoal obtenha a autorização apropriada antes de
              armazenar os Dados fora dos locais e sistemas aprovados pela
              Taxcel, acessar remotamente os Dados ou processar Dados fora das
              instalações das Partes.
            </li>
          </ol>
          <br />
          <p>
            Em relação aos Dados que saem do ambiente da Taxcel, a Taxcel irá:
          </p>
          <ol>
            <li>
              Criptografar os Dados do Cliente que ela transmitir em redes
              públicas.
            </li>
            <li>
              Proteger os Dados do Cliente na mídia que sai de suas instalações
              (por exemplo, por meio de criptografia).
            </li>
            <li>
              Implementar ferramentas automatizadas onde for viável para reduzir
              os riscos de e-mail, cartas e / ou faxes mal direcionados a partir
              de seus sistemas.
            </li>
          </ol>

          <h2>Sanções e Punições</h2>
          <p>
            A área de Segurança da Informação realiza o monitoramento contínuo
            do ambiente tecnológico por meio de métodos diversos para assegurar
            a conformidade e adesão a esta Política. Caso haja violação das
            regras nela dispostas, bem como as demais normas e procedimentos de
            Segurança da Informação, mesmo que por omissão ou tentativa não
            consumada, tal violação pode ser classificada como incidente de
            Segurança da Informação, os quais são passíveis de penalidades.
          </p>
          <p>
            As demais sanções e punições para o descumprimento das regras de
            Segurança da Informação estão descritas na Política interna.
          </p>

          <p>
            Última Atualização: setembro de 2023. <br />
            Classificação da Informação: Uso Público.
          </p>
        </Container>
      </Politica>
    </Layout>
  );
}
